/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h4: "h4"
  }, _provideComponents(), props.components), {VideoIframe, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think hearing aids will make you look or feel old, then these 5 hearing aid success stories will change your mind. Thanks to recent advancements in hearing aid technology, today’s devices are more effective and more discreet than ever before. In fact, they are changing the lives of millions of people from all walks of life. Perceptions are changing and people are talking. Hearing aids are quickly becoming the next big thing in wearable hearing tech. Just watch these 5 awesome stories of cool, active individuals who are making the most of their best years with the use of hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "adam-savage-from-myth-busters-shares-his-hearing-aid-success-story",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adam-savage-from-myth-busters-shares-his-hearing-aid-success-story",
    "aria-label": "adam savage from myth busters shares his hearing aid success story permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adam Savage from Myth Busters shares his hearing aid success story."), "\n", React.createElement(VideoIframe, {
    title: "Adam Savage Talks about His Hearing Loss!",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/D-Ptuq85R8Y"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dave-gellis-from-blood-sweat--tears-continues-to-rock-with-his-hearing-aids-and-wishes-he-would-have-done-it-sooner",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dave-gellis-from-blood-sweat--tears-continues-to-rock-with-his-hearing-aids-and-wishes-he-would-have-done-it-sooner",
    "aria-label": "dave gellis from blood sweat  tears continues to rock with his hearing aids and wishes he would have done it sooner permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dave Gellis from Blood, Sweat & Tears continues to rock with his hearing aids and wishes he would have done it sooner."), "\n", React.createElement(VideoIframe, {
    title: "Signia Pure Charge&Go Testimonial Video",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/HYWbsu8nAnw"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-opened-up-claires-world-and-helped-her-realize-her-dream-career-as-a-firefighter",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-opened-up-claires-world-and-helped-her-realize-her-dream-career-as-a-firefighter",
    "aria-label": "hearing aids opened up claires world and helped her realize her dream career as a firefighter permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids opened up Claire’s world and helped her realize her dream career as a firefighter."), "\n", React.createElement(VideoIframe, {
    title: "Clare Wolf Testimonial",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/TMoiC4gLL8g"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "gavin-demonstrates-how-easy-it-is-to-use-modern-hearing-aid-tech-with-his-phone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gavin-demonstrates-how-easy-it-is-to-use-modern-hearing-aid-tech-with-his-phone",
    "aria-label": "gavin demonstrates how easy it is to use modern hearing aid tech with his phone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gavin demonstrates how easy it is to use modern hearing aid tech with his phone."), "\n", React.createElement(VideoIframe, {
    title: "Gavin is using Widex EVOKE - hear what he thinks",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/TNj78fiWAEU"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "former-football-pro-terry-hanratty-shares-his-initial-hesitation-but-how-trying-modern-hearing-aids-changed-his-perception",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#former-football-pro-terry-hanratty-shares-his-initial-hesitation-but-how-trying-modern-hearing-aids-changed-his-perception",
    "aria-label": "former football pro terry hanratty shares his initial hesitation but how trying modern hearing aids changed his perception permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Former Football Pro Terry Hanratty shares his initial hesitation, but how trying modern hearing aids changed his perception."), "\n", React.createElement(VideoIframe, {
    title: "Pure Charge&Go X Testimonial: Terry Hanratty | Signia Hearing Aids",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/y-L8BlkPeN0"
  }), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "want-your-own-hearing-aid-success-story-heres-how-to-create-one-with-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#want-your-own-hearing-aid-success-story-heres-how-to-create-one-with-hearcom",
    "aria-label": "want your own hearing aid success story heres how to create one with hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Want your own hearing aid success story? Here’s how to create one with hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best way to experience the benefits of modern, medical-grade hearing aids is to take advantage of a 30-day trial period. hear.com works with all the leading brands, offers expert consultations, and provides access to hundreds of local hearing care professionals to get you on the path to hearing success. We highly encourage you to secure a 30-day trial if you haven’t done so already. Otherwise, we look forward to hearing all about your personal success story."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
